import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AttributeItem } from '../types/AttributeItem';

import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';

class AttributeService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);

        this.getAll = this.getAll.bind(this);
    }

    public getAll(): Promise<Array<AttributeItem>> {
        return this.get<Array<AttributeItem>>(`admin/attribute/get`)
            .then((result: AxiosResponse<Array<AttributeItem>>) => {
                return result.data
            });
    }
}

export const attributeApi = new AttributeService(apiConfig);