import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Model } from '../types/Model';

import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';

class ModelService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);

        this.getAll = this.getAll.bind(this);
    }

    public getAll(): Promise<Array<Model>> {
        return this.get<Array<Model>>(`admin/model/get`)
            .then((result: AxiosResponse<Array<Model>>) => {
                return result.data
            });
    }
}

export const modelApi = new ModelService(apiConfig);