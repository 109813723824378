import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FileItem } from '../types/FileItem';

import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';

class FileService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);

        this.getAll = this.getAll.bind(this);
        this.remove = this.remove.bind(this);
    }

    public getAll(entityType: number, entityId: number): Promise<Array<FileItem>> {
        return this.get<Array<FileItem>>(`file/get/${entityType}/${entityId}`)
            .then((result: AxiosResponse<Array<FileItem>>) => {
                return result.data
            });
    }

    public remove(entityType: number, entityId: number, fileId: number): Promise<Array<FileItem>> {
        return this.delete<any>(`admin/file/${entityType}/${entityId}/${fileId}`)
            .then((result: AxiosResponse<any>) => {
                return result.data
            });
    }
}

export const fileApi = new FileService(apiConfig);