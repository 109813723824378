import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ArticleViewModel } from '../types/ArticleViewModel';
import { SaveArticle } from '@/types/SaveArticle';

import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';

class ArticleService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);

        this.getAllActive = this.getAllActive.bind(this);
        this.getAll = this.getAll.bind(this);
        this.fetch = this.fetch.bind(this);
        this.add = this.add.bind(this);
        this.fetchById = this.fetchById.bind(this);
    }

    public getAllActive(): Promise<Array<ArticleViewModel>> {
        return this.get<Array<ArticleViewModel>>(`article/get`)
            .then((result: AxiosResponse<Array<ArticleViewModel>>) => {
                return result.data
            });
    }

    public getAll(): Promise<Array<ArticleViewModel>> {
        return this.get<Array<ArticleViewModel>>(`admin/article/get`)
            .then((result: AxiosResponse<Array<ArticleViewModel>>) => {
                return result.data
            });
    }

    public fetch(articleSlug: string): Promise<ArticleViewModel> {
        return this.get<ArticleViewModel>(`article/get/${articleSlug}`)
            .then((result: AxiosResponse<ArticleViewModel>) => {
                return result.data;
            })
    }

    public fetchById(id: number): Promise<SaveArticle> {
        return this.get<SaveArticle>(`admin/article/get/${id}`)
            .then((result: AxiosResponse<SaveArticle>) => {
                return result.data;
            })
    }

    public add(dto: FormData): Promise<boolean> {
        return this.post(`admin/article`, dto, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((result: AxiosResponse<any>) => {
            return result.data
        });
    }
}

export const articleApi = new ArticleService(apiConfig);