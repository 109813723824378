import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SuitsFor } from '@/types/SuitsFor';

import { Api } from '../utils/api/api';
import { apiConfig } from '../utils/constants';

class SuitableForService extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig);

        this.getAll = this.getAll.bind(this);
    }

    public getAll(): Promise<Array<SuitsFor>> {
        return this.get<Array<SuitsFor>>(`admin/suitablefor/get`)
            .then((result: AxiosResponse<Array<SuitsFor>>) => {
                return result.data
            });
    }
}

export const suitableForApi = new SuitableForService(apiConfig);